import React from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

import StreakCalendar from '~/components/streaks/StreakCalendar'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import useStreakData from '~/hooks/useStreakData'

const { Body2, Heading3, MicroText1BoldCaps } = fonts

const StreakCard = (): JSX.Element => {
  const { t } = useTranslation('common')
  const { loading, error, currentStreakLength, bestStreakLength } = useStreakData()
  const loaded = !(loading || error)

  const daysString = (count: number) => {
    const dayKey = count === 1 ? 'day' : 'days'
    return t(`streaks.${dayKey}`, { count: count })
  }

  const currentStreakText = loaded ? daysString(currentStreakLength || 0) : ''
  const bestStreakText = loaded ? daysString(bestStreakLength || 0) : ''

  return (
    <CardContainer>
      <CardTitle>{t('streaks.yourStreaks')}</CardTitle>
      <StreakUnderline />
      <CalendarContainer>
        <StreakCalendar inkBackground={false} />
      </CalendarContainer>
      <StreakLengthsContainer>
        <Row>
          <Heading3>{currentStreakText}</Heading3>
          <Heading3>{bestStreakText}</Heading3>
        </Row>
        <Row>
          <StreakLabelsText>{t('streaks.currentStreak')}</StreakLabelsText>
          <StreakLabelsText>{t('streaks.bestStreak')}</StreakLabelsText>
        </Row>
      </StreakLengthsContainer>
    </CardContainer>
  )
}

const CardContainer = styled(CardShadowRadius)`
  width: 326px;
  height: 241px;
`

const CardTitle = styled(MicroText1BoldCaps)`
  color: ${({ theme }) => theme.colors.coolGray62};
  padding: 18px 18px 8px;
  display: block;
`

const StreakUnderline = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.coolGray92};
  border: none;
`

const StreakLengthsContainer = styled.div`
  margin: 22px 10px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1fr;
  text-align: center;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  > * {
    flex: auto;
  }
`

const StreakLabelsText = styled(Body2)`
  color: ${(props) => props.theme.colors.coolGray44};
  margin-top: 8px;
  margin-right: 10px;
`

const CalendarContainer = styled.div`
  color: ${(props) => props.theme.colors.coolGray44};
`

export default StreakCard
