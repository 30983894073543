import { useEffect, useState } from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import MarkerImage from '~/images/ProgressBar/badge-best.svg'

const { MicroText1BoldCaps } = fonts

export interface ProgressBarProps {
  progress: number
  withBackground?: boolean
  backgroundColor?: string
  height?: number
  color?: string
  transitionTimingFunction?: string
}

export interface AnimatedProgressBarProps extends ProgressBarProps {
  delay: number
}

export interface ProgressMeterProps extends AnimatedProgressBarProps {
  marker?: {
    position: number
    label: string
  }
}

export const ProgressMeter: React.FC<ProgressMeterProps> = ({ color, marker, ...props }) => (
  <Container>
    <AnimatedProgressBar {...props} color={color}></AnimatedProgressBar>
    {marker && (
      <MarkerContainer position={marker.position}>
        <MarkerLabel color={color}>
          <MarkerImage title={marker.label} />
          <MarkerLabelText as='div'>{marker.label}</MarkerLabelText>
        </MarkerLabel>
      </MarkerContainer>
    )}
  </Container>
)

export const AnimatedProgressBar: React.FC<AnimatedProgressBarProps> = ({ delay, progress, ...props }) => {
  const [currentProgress, setCurrentProgress] = useState(1)
  useEffect(() => {
    setCurrentProgress(1)
    const timeout = setTimeout(() => {
      setCurrentProgress(progress || 1)
    }, delay * 250 || 1)
    return () => {
      setCurrentProgress(progress)
      clearTimeout(timeout)
    }
  }, [progress])
  return <ProgressBar progress={currentProgress} {...props} />
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, ...styles }) => (
  <ProgressContainer {...styles}>
    <FilledBar progress={progress} />
  </ProgressContainer>
)

const Container = styled.div`
  width: 100%;
  display: grid;
`

const FilledBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => (progress > 100 ? 100 : progress)}%;
`

const MarkerContainer = styled.div<{ position: number }>`
  position: relative;
  height: 58px;
  text-align: center;
  transform: translateX(${({ position }) => position}%);
`

const MarkerLabel = styled.div<Pick<ProgressBarProps, 'color'>>`
  text-align: center;
  color: ${({ theme, color }) => (color ? color : theme.colors.orangeBase)};
  transform: translateX(-50%);
`

const MarkerLabelText = styled(MicroText1BoldCaps)`
  color: ${({ theme }) => theme.colors.coolGray44};
`

const ProgressContainer = styled.div<Omit<ProgressBarProps, 'progress'>>`
  position: relative;
  border-radius: 40px;
  height: ${({ height }) => height || '12'}px;
  width: 100%;
  background: ${({ withBackground = true, theme, backgroundColor }) =>
    withBackground ? (backgroundColor ? backgroundColor : theme.colors.coolGray95) : 'none'};
  /* stylelint-disable-next-line no-descending-specificity */
  div {
    display: block;
    transition: all ${({ theme }) => theme.animation.durationBase}
      ${({ transitionTimingFunction = 'linear' }) => transitionTimingFunction};
    height: 100%;
    border-radius: 40px;
    background: ${({ color, theme }) => {
      if (color?.includes('Gradient')) {
        return (theme.colors as Record<string, string>)[color]
      }
      return color || theme.colors.tealBase
    }};
    position: relative;
    overflow: hidden;
  }
`

export default ProgressBar
